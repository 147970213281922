<template>
  <div class="dropDownWrap">
    <el-dropdown trigger="click"  @command="toUrl">
      <span class="el-dropdown-link">
        <slot></slot>
      </span>
      <el-dropdown-menu slot="dropdown">
         <el-dropdown-item v-for='(item,index) in menuList' :key='index' :command="item.id" >
          {{item.name}}
         </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: "dropDown",
  components: {},
  props:{
    menuList:{
      type:Array,
      default:()=>[]
    }
  },
  data() {
    return {};
  },
  methods:{
    toUrl(target){
      this.$goUrl(target)
    }
  }
};
</script>

<style lang="scss" scoped>
.dropDownWrap {
  .el-dropdown-link {
    color: #fff;
    font-size: 20px;
  }
 
  
  
}
 .el-dropdown-menu.el-popper{
  border-radius: 8px;
}
.el-dropdown-menu__item{
    padding: 5px 15px;
    font-size: 20px;
    font-weight: bold;
    &:hover{
      background-color: #222;
      opacity: .5;
      color: #fff;
    }
  }
  @media screen and (max-width:500px) {
    .dropDownWrap {
  .el-dropdown-link {
    color: #fff;
    font-size: 13PX;
  }
  
  
}

 .el-dropdown-menu.el-popper{
  
  border-radius: 6PX;
}
    .el-dropdown-menu__item{
    padding: 10PX 5PX;
    font-size: 15PX;
    font-weight: bold;
    &:hover{
      background-color: #222;
      opacity: .5;
      color: #fff;
    }
  }
  }
</style>
