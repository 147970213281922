// import 'lib-flexible/flexible.js'
import 'amfe-flexible/index.js'
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import {Dropdown,
  DropdownMenu,
  DropdownItem,Dialog} from 'element-ui';
import VueI18n from "vue-i18n";
import './assets/icons/index.js'
// import 'element-ui/lib/theme-chalk/index.css';
import "./assets/reset.css";
// import "./assets/font/iconfont.css"

Vue.config.productionTip = false;
// Vue.use(ElementUI);
Vue.use(Dropdown).use(DropdownMenu).use(DropdownItem).use(Dialog)
Vue.use(VueI18n);
Vue.prototype.$goUrl=(target)=>{
  if(target){
    window.open(target)
  }else{
    alert('Coming Soon')
  }
}
const i18n = new VueI18n({
  locale: "zh", // 定义默认语言为中文
  messages: {
    zh: require("./assets/languages/zh.json"),
    en: require("./assets/languages/en.json")
  }
});
new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
