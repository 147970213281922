<template>
  <div id="wrap">
    <div class="blank" ref="blank"></div>
    <div class="header">
      <div class="left">
        <img src="@assets/img/logo_sc.png" alt="" />
        <span>ShowApp</span>
      </div>
      <div class="right">
        <div class="item">
          <DropDown :menuList="upLoadList">{{ $t("h1") }}</DropDown>
        </div>
        <div class="item" @click.stop="toUrl(routeMap)">{{ $t("h2") }}</div>
        <div class="item" @click.stop="isShowAppCenter = true">
          {{ $t("h3") }}
        </div>
        <div class="item">
          <DropDown :menuList="socialList">{{ $t("h4") }}</DropDown>
        </div>
        <div class="item" @click.stop="toggleLeng">
          {{ this.lang === "zh" ? "EN" : "CN" }}
        </div>
      </div>
    </div>
    <div class="pageOne">
      <div class="videoWrap">
        <div class="phoneImg"><img :src="phoneImg" alt="" /></div>
        <!-- <img :src="bgImg" alt="" class="bgImg" /> -->
        <!-- <img class="bgImg" :src="bgImg" alt="" v-if="checkPlatform" />
        <video
          v-else
          ref="video"
          muted
          src="@/assets/video/sv.mp4"
          webkit-playsinline="true"
          playsinline="true"
          autoplay="autoplay"
          preload="metadata"
          x-webkit-airplay="true"
          x5-video-orientation="portraint"
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
        ></video> -->

        <div class="rightWrap">
          <div class="titleWrap">
            <span>{{ $t("p1-1") }}</span>
            <span>{{ $t("p1-2") }}</span>
          </div>
          <div class="contentWrap">
            <div class="upload">
              <div class="item" @click.stop="toUrl(appStore)">
                <svg-icon icon-class="apple_icon"></svg-icon>
                <span>App Store</span>
              </div>
              <div class="item" @click.stop="toUrl(andriod)">
                <svg-icon icon-class="googlePlay_icon"></svg-icon>
                <span>Google Play</span>
              </div>
              <div class="item" @click.stop="toUrl(apk)">
                <svg-icon icon-class="apk_icon"></svg-icon>
                <span>Android APK</span>
              </div>
              <!-- <img
                @click.stop="toUrl(appStore)"
                src="@assets/img/bth_App Store.png"
                alt=""
              />
              <img
                @click.stop="toUrl(andriod)"
                src="@assets/img/bth_android.png"
                alt=""
              />
              <img
                @click.stop="toUrl(apk)"
                src="@assets/img/bth_apk.png"
                alt=""
              /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pageTwo">
      <div class="contentWrap">
        <div class="item">
          <img src="@assets/img/1.png" alt="" />
          <div class="contentItem">
            <span>{{ $t("p2-1") }}</span>
            <span>{{ $t("p2-2") }}</span>
            <span>{{ $t("p2-3") }}</span>
          </div>
        </div>
        <div class="item">
          <img src="@assets/img/2.png" alt="" />
          <div class="contentItem">
            <span>{{ $t("p2-4") }}</span>
            <span>{{ $t("p2-5") }}</span>
            <span>{{ $t("p2-6") }}</span>
            <span>{{ $t("p2-7") }}</span>
          </div>
        </div>
        <div class="item">
          <img src="@assets/img/3.png" alt="" />
          <div class="contentItem">
            <span>{{ $t("p2-8") }}</span>
            <span>{{ $t("p2-9") }}</span>
            <span>{{ $t("p2-10") }}</span>
            <span>{{ $t("p2-11") }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="pageThree">
      <div class="top">
        <span>{{ $t("p3-1") }}</span>
        <span>{{ $t("p3-2") }}</span>
      </div>
      <div class="center">
        <div class="bgItem"></div>
        <div class="bgItem"></div>
      </div>
    </div>
    <div class="pageFour">
      <div class="content">
        <span>{{ $t("p4-1") }}</span>
        <span>{{ $t("p4-2") }}</span>
      </div>
    </div>
    <div class="pageFive">
      <div class="content">
        <span>{{ $t("p5-1") }}</span>
        <span>{{ $t("p5-2") }}</span>
      </div>
    </div>
    <div class="pageSix">
      <div class="wrap">
        <div class="title">{{ $t("p6-1") }}</div>
        <div class="listWrap">
          <div class="item">
            <div class="top">
              <img src="@assets/img/logo-metaid.png" alt="" />
            </div>
            <div class="center">
              <span>MetaID</span>
              <span>{{ $t("p6-3") }}</span>
            </div>
            <div class="foot" @click.stop="toUrl(metaidIo)">
              <span>{{ $t("p6-4") }}</span>
              <svg-icon icon-class="right"></svg-icon>
              <!-- <i class="el-icon-caret-right" ></i> -->
            </div>
          </div>
          <div class="item">
            <div class="top">
              <img src="@assets/img/logo_nos.png" alt="" />
            </div>
            <div class="center">
              <span>{{ $t("p6-5") }}</span>
              <span>{{ $t("p6-6") }}</span>
            </div>
            <div class="foot" @click.stop="toUrl(nos)">
              <span>{{ $t("p6-7") }}</span>
              <svg-icon icon-class="right"></svg-icon>
              <!-- <i class="el-icon-caret-right" ></i> -->
            </div>
          </div>
          <div class="item">
            <div class="top">
              <img src="@assets/img/logo_showmoney.png" alt="" />
            </div>
            <div class="center">
              <span>{{ $t("p6-8") }}</span>
              <span>{{ $t("p6-9") }}</span>
            </div>
            <div class="foot" @click.stop="toUrl(showmoney)">
              <span>{{ $t("p6-10") }}</span>
              <svg-icon icon-class="right"></svg-icon>
              <!-- <i class="el-icon-caret-right" ></i> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="pageSeven">
      <div class="wrap">
        <div class="title">{{ $t("p7-1") }}</div>
        <div class="listWrap">
          <div class="item">
            <div class="top">
              <img src="@assets/img/logo_mc.png" alt="" />
            </div>
            <div class="center">
              <span>MetaCoin</span>
            </div>
            <div class="foot" @click.stop="toUrl(omf)">
              <span>{{ $t("p7-2") }}</span>
              <svg-icon icon-class="right"></svg-icon>

            </div>
          </div>
          <div class="item">
            <div class="top">
              <img src="@assets/img/logo_sc.png" alt="" />
            </div>
            <div class="center">
              <span>ShowCoin</span>
            </div>
            <div class="foot" @click.stop="toUrl(showCoin)">
              <span>{{ $t("p7-3") }}</span>
              <svg-icon icon-class="right"></svg-icon>
           
            </div>
          </div>
          <div class="item">
            <div class="top">
              <img src="@assets/img/logo_mdc.png" alt="" />
            </div>
            <div class="center">
              <span>MetaDevCoin</span>
            </div>
            <div class="foot" @click.stop="toUrl(metaDev)">
              <span>Coming Soon</span>
              <svg-icon icon-class="right"></svg-icon>
         
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="pageEight">
      <div class="wrap">
        <div class="title">{{ $t("p8-1") }}</div>
        <div class="content">
          <span>{{ $t("p8-2") }}</span>
          <span>{{ $t("p8-3") }}</span>
        </div>
        <div class="toUrl" @click.stop="toUrl(showPayIo)">
          <span>{{ $t("p8-4") }}</span>
          <svg-icon icon-class="right"></svg-icon>
          <!-- <i class="el-icon-caret-right" ></i> -->
        </div>
      </div>
    </div>
    <footer>
      <div class="contanier">
        <div class="left">
          <span>ShowApp</span>
          <span>{{ $t("p9-1") }}</span>
        </div>
        <div class="right">
          <div class="item">
            <div class="title">{{ $t("p9-2") }}</div>
            <span @click.stop="toUrl(appStore)">App Store</span>
            <span @click.stop="toUrl(andriod)">Google Play</span>
            <span @click.stop="toUrl(apk)">Android APK</span>
          </div>
          <div class="item">
            <div class="title">{{ $t("p9-3") }}</div>
            <span @click.stop="toUrl(Discord)">Discord</span>
            <span @click.stop="toUrl(Telegram)">Telegram</span>
            <span @click.stop="toUrl(Twitter)">Twitter</span>
            <span @click.stop="toUrl(weibo)">Weibo</span>
          </div>
        </div>
      </div>
    </footer>
    <div class="beian">
      <a
        href="http://www.beian.gov.cn/portal/registerSystemInfo?spm=a21bo.jianhua.1997523009.39.5af911d9bCyqry&amp;recordcode=44200002444919"
        target="_blank"
      >
        <img src="@/assets/img/logo_ga.png" />粤公网安备 44200002444919</a
      >
      <span>|</span>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
        >粤ICP备19109852号</a
      >
    </div>
    <el-dialog
      :append-to-body="true"
      :modal-append-to-body="true"
      :visible.sync="imgDialog"
      class="img_box"
    >
      <img @click.stop="imgDialog = false" :src="skipTips" alt />
    </el-dialog>

    <!-- 应用广场 -->
    <el-dialog
      :visible.sync="isShowAppCenter"
      custom-class="app-center-modal"
      :lock-scroll="true"
    >
      <iframe
        :src="`https://www.nftonshow.com/app/index?lang=${$i18n.locale}`"
        frameborder="0"
      ></iframe>
    </el-dialog>
  </div>
</template>
<script>
import DropDown from "@c/dropDown.vue";

export default {
  name: "Home",
  components: {
    DropDown,
  },
  data() {
    return {
      imgDialog: false,
      skipTips: require("../assets/img/skip.png"),
      // video:`https://f.video.weibocdn.com/o0/QYAMJy7ulx07RZUsGpMY01041202tomn0E010.mp4?label=mp4_1080p&template=1920x1080.25.0&trans_finger=d88af6227b88881484d2b59dfbafa136&media_id=4712076317491341&tp=8x8A3El:YTkl0eM8&us=0&ori=1&bf=3&ot=h&lp=0000158TSW&ps=mZ6WB&uid=8i0lsG&ab=3915-g1,5627-g0,6377-g0,1192-g0,5424-g1,1258-g0,3601-g15&Expires=1638955739&ssig=YnobbxJoRH&KID=unistore,video`,
      // video:"filecdn.showpay.top/showpay/showsv/sv-en.mp4",
      uploadShow: false,
      titleShow: false,
      appStore: "https://apps.apple.com/us/app/show-app/id1549441615",
      andriod:
        "https://play.google.com/store/apps/details?id=app.showmoney.showmoney",
      // apk: "https://show.sv/app/show.apk",
      apk: "https://showpay.oss-cn-beijing.aliyuncs.com/app/show.apk",
      metaidIo: "https://www.metaid.io",
      nos: "https://h5.nos.art/home",
      showmoney: "https://www.showmoney.app",
      omf: "https://www.omf.foundation/MetaCoin",
      // showCoin: "",
      metaDev: "",
      showPayIo: "https://showpay.io",
      Twitter: "https://twitter.com/showappofficial",
      Discord: "https://discord.gg/Ut7SXXV8Sa",
      Telegram: "https://t.me/showappofficial",
      weibo: "https://weibo.com/u/7720543099",
      lang: "zh",
      isEnd: false,
      isShowAppCenter: false,
    };
  },
  mounted() {
    // this.weixinPlay();
    // if (this.checkPlatform) {
    //   setTimeout(() => {
    //     this.titleShow = true;
    //     this.isEnd = true;
    //     this.$nextTick(() => (this.$refs.blank.style.height = "65PX"));
    //     setTimeout(() => {
    //       this.uploadShow = true;
    //     }, 1000);
    //   }, 8000);
    // } else {
    //   this.$refs.video.addEventListener(
    //     "ended",
    //     () => {
    //       this.titleShow = true;
    //       setTimeout(() => {
    //         this.uploadShow = true;
    //       }, 1000);
    //       this.$nextTick(() => (this.$refs.blank.style.height = "65PX"));
    //     },
    //     false
    //   );
    // }
  },

  methods: {
    weixinPlay() {
      document.addEventListener(
        "WeixinJSBridgeReady",
        () => {
          this.$refs.video.play();
        },
        false
      );
    },
    toUrl(target) {
      if (
        this.isWeiXin &&
        (target === this.appStore ||
          target === this.andriod ||
          target === this.apk)
      ) {
        this.imgDialog = true;
        return;
      }
      this.$goUrl(target);
    },
    toggleLeng() {
      if (this.lang === "zh") {
        this.$i18n.locale = this.lang = "en";
      } else {
        this.$i18n.locale = this.lang = "zh";
      }
    },
  },
  computed: {
    showCoin() {
      if (this.$i18n.locale === "en") {
        return `https://www.showfuture.foundation/docs/showcoinwhitepaper_en.pdf`;
      } else {
        return `https://www.showfuture.foundation/docs/showcoinwhitepaper_cn.pdf`;
      }
    },
    routeMap() {
      if (this.$i18n.locale === "en") {
        return `https://www.showfuture.foundation/docs/Show-Platform-Introduction-and-Future-Planning.pdf`;
      } else {
        return `https://www.showfuture.foundation/docs/showintroduction_cn.pdf`;
      }
    },
    phoneImg() {
      if (this.$i18n.locale === "en") {
        return require("@/assets/img/p1-en.png");
      } else {
        return require("@/assets/img/p1-cn.png");
      }
    },
    checkPlatform() {
      let userAgent = navigator.userAgent;
      let android =
        userAgent.indexOf("Android") > -1 || userAgent.indexOf("Linux") > -1;
      if (android) {
        return true;
      } else {
        return false;
      }
    },

    upLoadList() {
      return [
        {
          name: "App Store",
          id: this.appStore,
        },
        {
          name: "Google Play",
          id: this.andriod,
        },
        {
          name: "APK",
          id: this.apk,
        },
      ];
    },
    socialList() {
      return [
        {
          name: "Discord",
          id: this.Discord,
        },
        {
          name: "Telegram",
          id: this.Telegram,
        },
        {
          name: "Twitter",
          id: this.Twitter,
        },
        {
          name: "weibo",
          id: this.weibo,
        },
      ];
    },
    browser() {
      return window.navigator.userAgent.toLowerCase();
    },
    isWeiXin() {
      if (this.browser.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped src="./Home.scss"></style>
